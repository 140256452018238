@media print {
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 0;
    margin-left: 16mm;
    margin-right: 16mm;
  }
  html, body {
      height: 282mm;
      font-size: 11px;
      background: #FFF;
      overflow: visible;
  }
  body {
      padding: 16mm 0mm 16mm 0mm; 
  }

  #quotePdf {
    transform: translateY(-200px); 
  }

  .noPrint {
    visibility: hidden;
  }

  #termsBox {
    border: 0px;
    height: auto !important;
  }
}