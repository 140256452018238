h1, h2, h3, h4, h5 {
  font-weight: 400;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 16px;
}

p {
  font-size: 14px;
}

