@import "../../../assets/scss/variables";

.jobs-view-tabs {
  width: 100%;
  min-width: 100px;
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid $color-secondary-main;
  .view-tabs-hidden {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: -100000px;
    right: 100000px;
  }

  .view-tabs-container {
    display: flex;
    flex-wrap: wrap;
    max-height: 36px;
    overflow: hidden;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
  }
}

.view-tab {
  font-weight: 500;
  height: 100%;
  padding: 4px 0 0 16px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: transparent;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transition: background-color 0.2s;
  border-bottom: 2px solid transparent;

  &.home {
    padding-left: 8px;
  }

  &::after {
    content: '';
    border-right: 1px solid $color-secondary-main;
    height: 20px;
  }

  &:hover {
    background: $color-secondary-light;
    border-bottom: 2px solid $color-secondary-light;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  &.selected, &.selected:hover {
    color: $color-primary-main;
    border-bottom: 2px solid $color-primary-main;
  }

  .icon-home {
    height: 18px;
    padding-right: 2px;
    margin-top: -2px;
    opacity: 1;
    fill: $color-secondary-dark;
  }
  &.selected .icon-home {
    fill: $color-primary-main;
  }

  & > svg {
    opacity: 0;
    transition: opacity 0.3s;
    margin: 0 0;
  }

  &:hover > svg {
    opacity: 1;
  }

  .icon-hidden {
    opacity: 0 !important;
  }

  &:hover:not(:focus-within) .view-tab-menu {
    opacity: 1;
  }
  .view-tab-menu {
    min-width: 30px;
    min-height: 30px;
    position: relative;
    opacity: 0;
    transition: opacity 0.3s;

    svg {
      margin-top: 2px;
      height: 26px;
      width: 26px;
    }
    button {
      height: 30px;
      width: 30px;
      margin-bottom: 0;
    }
    > svg {
      opacity: 0;
      width: 30px;
      height: 30px;
      padding: 3px;
      z-index: 100;
    }
  }
}

.more-views {
  padding-left: 0;
  position: relative;

  button {
    padding: 8px 16px 4px 16px;
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  svg {
    margin-top: -3px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 10px;
    border-right: 1px solid $color-secondary-main;
    height: 20px;
  }
}

.add-view-button {
  width: 110px;

  > button {
    padding: 8px 16px 4px 16px;
    white-space: nowrap;
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  svg {
    margin-top: -3px;
  }
}
