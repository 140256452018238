$color-primary-main: #00CCF0;
$color-primary-dark: #14AADC;

$color-secondary-dark: #2F323C;
$color-secondary-main: #D9DADC;
$color-secondary-light: #E8EAED;
$color-secondary-middle: #8F9197;
$color-secondary-drag-handle: #C4C4C4;


$color-theme-red: #FC5F65;
$color-theme-green: #22CC96;
$color-theme-orange: #FFBF66;
$color-theme-purple: #9366AF;

