html,
body {
  min-height: 100%;
  height: 100%;
  max-width: 100vw;
  width: 100vw;
}

body {
  font-family: "Rubik", "Open Sans", "Helvetica Neue", sans-serif;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
}

a,
u {
  text-underline-position: under;
  -ms-text-underline-position: below;
}

.MuiModal-root > .MuiPaper-root.MuiPopover-paper {
  border: 1px solid $color-secondary-main;
}
