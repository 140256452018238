/* 
DayPilot Scheduler Theme
https://themes.daypilot.org/scheduler/theme/nfgbjf
Theme Designer Version: 2020.06.01.40518
*/
.scheduler_w_border_main 
{
    border: 1px solid #d9dadc;
}
.scheduler_w_border_main, .scheduler_w_border_main td 
{
    font-family: rubik;
    font-size: 12px;
}
.scheduler_w_border_event { 
}
.scheduler_w_border_event { 
    color: #ffffff;
}
.scheduler_w_border_event_inner { 
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: 0px;
    overflow: hidden;
    background-color: #00ccf0;
    padding: 2px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
}
.scheduler_w_border_timeheadergroup,
.scheduler_w_border_timeheadercol
{
    color: #2f323c;
    background: #f3f3f6;
}
.scheduler_w_border_rowheader,
.scheduler_w_border_corner
{
    color: #2f323c;
    background: #f3f3f6;
}
.scheduler_w_border_rowheader_inner {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
    border-right: 1px solid #e0e0e0;
    padding: 7px;
    display: flex;
    align-items: center;
}
.scheduler_w_border_timeheadergroup,
.scheduler_w_border_timeheadercol
{
}
.scheduler_w_border_timeheadergroup_inner,
.scheduler_w_border_timeheadercol_inner
{
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border-right: 1px solid #d9dadc;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.scheduler_w_border_timeheadergroup_inner {
    border-bottom: 1px solid #d9dadc;
}
.scheduler_w_border_divider,
.scheduler_w_border_splitter,
.scheduler_w_border_divider_horizontal,
.scheduler_w_border_resourcedivider 
{
    background-color: #d9dadc;
}
.scheduler_w_border_matrix_vertical_line,
.scheduler_w_border_matrix_horizontal_line 
{
    background-color: #e8eaed;
}
.scheduler_w_border_cell 
{
    background: #f9f9f9;
}
.scheduler_w_border_cell.scheduler_w_border_cell_business 
{
    background: #ffffff;
}
.scheduler_w_border_message 
{
    padding: 10px;
    opacity: 0.9;
    color: #ffffff;
    background: #22cc96;
}
.scheduler_w_border_shadow_inner 
{
    background-color: #666666;
    opacity: 0.5;
    height: 100%;
}
.scheduler_w_border_matrix_vertical_break 
{
    background-color: #999;
}
.scheduler_w_border_cellparent {
}
.scheduler_w_border_tree_image_no_children {
}
.scheduler_w_border_tree_image_expand {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><path d='M 1.5 0.5 L 6.5 5 L 1.5 9.5' style='fill:none;stroke:%23999999;stroke-width:2;stroke-linejoin:round;stroke-linecap:butt' /></svg>");
}
.scheduler_w_border_tree_image_collapse {
    background-image: url("data:image/svg+xml;utf8,<svg width='10' height='10' xmlns='http://www.w3.org/2000/svg'><path d='M 0.5 1.5 L 5 6.5 L 9.5 1.5' style='fill:none;stroke:%23999999;stroke-width:2;stroke-linejoin:round;stroke-linecap:butt' /></svg>");
}
.scheduler_w_border_columnheader 
{
}
.scheduler_w_border_columnheader_inner {
    font-weight: bold;
}
.scheduler_w_border_columnheader_cell {
}
.scheduler_w_border_columnheader_splitter { box-sizing: border-box; border-right: 1px solid #c0c0c0; }
.scheduler_w_border_columnheader_splitter:hover { background-color: #c0c0c0; }
.scheduler_w_border_columnheader_cell_inner {
    position: absolute; left: 0px; right: 0px; top: 0px; bottom: 0px; padding: 2px; display: flex; align-items: center;
}
.scheduler_w_border_timeheader_float {
    display: flex;
    align-items: center;
    justify-content: center;
}
.scheduler_w_border_timeheader_float_inner {
    padding: 7px;
}
.scheduler_w_border_event_float {
    display: flex;
    align-items: center;
}
.scheduler_w_border_event_float_inner {
    padding: 3px 2px 2px 7px;
    padding-left: 9px;
    /*top: -2px;*/
    position: relative;    
}
.scheduler_w_border_event_float_inner:after {
    content:"";
    border-color: transparent #ffffff transparent transparent;
    border-style:solid;
    border-width:5px;
    width:0;
    height:0;
    position:absolute;
    top:5px;
    left:-4px;
}
.scheduler_w_border_event_move_left {
    box-sizing: border-box; 
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
    display: flex;
    align-items: center;
}
.scheduler_w_border_event_move_right {
    box-sizing: border-box; 
    padding: 2px;
    border: 1px solid #ccc;
    background: #fff;
    background: linear-gradient(to bottom, #ffffff 0%, #eeeeee);
    display: flex;
    align-items: center;
}
.scheduler_w_border_event_delete {
    background: url("data:image/svg+xml;utf8,<svg width='12' height='12' xmlns='http://www.w3.org/2000/svg'><path d='M 0.5 0.5 L 11.5 11.5 M 0.5 11.5 L 11.5 0.5' style='fill:none;stroke:%23464646;stroke-width:3;stroke-linejoin:round;stroke-linecap:butt' /></svg>") no-repeat center center; opacity: 0.6; cursor: pointer;
    cursor: pointer;
    opacity: 0.6;
}
.scheduler_w_border_event_delete:hover {
    opacity: 1;
}
.scheduler_w_border_rowmove_handle { background-repeat: no-repeat; background-position: center center; background-color: #ccc; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAKCAYAAACT+/8OAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjExR/NCNwAAAClJREFUGFdj+P//P4O9vX2Bg4NDP4gNFgBytgPxebgAMsYuQGMz/jMAAFsTZDPYJlDHAAAAAElFTkSuQmCC); cursor: move; }
.scheduler_w_border_rowmove_source { background-color: black; opacity: 0.2; }
.scheduler_w_border_rowmove_position_before, .scheduler_w_border_rowmove_position_after { background-color: #999; height: 2px; }
.scheduler_w_border_rowmove_position_child { margin-left: 10px; background-color: #999; height: 2px; }
.scheduler_w_border_rowmove_position_child:before { content: '+'; color: #999; position: absolute; top: -8px; left: -10px; }
.scheduler_w_border_rowmove_position_forbidden { background-color: red; height: 2px; margin-left: 10px; }
.scheduler_w_border_rowmove_position_forbidden:before { content: 'x'; color: red; position: absolute; top: -8px; left: -10px; }
.scheduler_w_border_link_horizontal { border-bottom-style: solid; border-bottom-color: red }
.scheduler_w_border_link_vertical { border-right-style: solid; border-right-color: red }
.scheduler_w_border_link_arrow_right:before { content: ''; border-width: 6px; border-color: transparent transparent transparent red; border-style: solid; width: 0px; height:0px; position: absolute; }
.scheduler_w_border_link_arrow_left:before { content: ''; border-width: 6px; border-color: transparent red transparent transparent; border-style: solid; width: 0px; height:0px; position: absolute; }
.scheduler_w_border_link_arrow_down:before { content: ''; border-width: 6px; border-color: red transparent transparent transparent; border-style: solid; width: 0px; height:0px; position: absolute; }
.scheduler_w_border_link_arrow_up:before { content: ''; border-width: 6px; border-color: transparent transparent red transparent; border-style: solid; width: 0px; height:0px; position: absolute; }
.scheduler_w_border_shadow_overlap .scheduler_w_border_shadow_inner { background-color: red; }
.scheduler_w_border_overlay { background-color: gray; opacity: 0.5; }
.scheduler_w_border_main .scheduler_w_border_event_group { box-sizing: border-box; font-size:12px; color:#666; padding:2px; overflow:hidden; border:1px solid #ccc; background-color: #fff; display: flex; align-items: center; white-space: nowrap; }
.scheduler_w_border_header_icon { box-sizing: border-box; border: 1px solid #d9dadc; background-color: #f3f3f6; color: #2f323c; }
.scheduler_w_border_header_icon:hover { background-color: #ededf2; }
.scheduler_w_border_header_icon_hide:before { content: '\00AB'; }
.scheduler_w_border_header_icon_show:before { content: '\00BB'; }
.scheduler_w_border_rowheader.scheduler_w_border_rowheader_selected { background-color: #aaa; background-image: linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent); background-size: 35px 35px; }
.scheduler_w_border_cell.scheduler_w_border_cell_business.scheduler_w_border_cell_selected, .scheduler_w_border_cell.scheduler_w_border_cell_selected { background-color: #ccc; background-image: linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent); background-size: 20px 20px; }
.scheduler_w_border_row_new .scheduler_w_border_rowheader_inner { cursor: text; background-position: 0px 50%; background-repeat: no-repeat; background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABUSURBVChTY0ACslAaK2CC0iCQDMSlECYmQFYIAl1AjFUxukIQwKoYm0IQwFCMSyEIaEJpMMClcD4Qp0CYEIBNIUzRPzAPCtAVYlWEDgyAGIdTGBgAbqEJYyjqa3oAAAAASUVORK5CYII=); }
.scheduler_w_border_row_new .scheduler_w_border_rowheader_inner:hover { background: white; }
.scheduler_w_border_rowheader textarea { padding: 3px; }
.scheduler_w_border_rowheader_scroll { cursor: default; }
.scheduler_w_border_shadow_forbidden .scheduler_w_border_shadow_inner { background-color: red; }
.scheduler_w_border_event_moving_source { opacity: 0.5; }
.scheduler_w_border_linkpoint { background-color: white; border: 1px solid gray; border-radius: 5px; }
.scheduler_w_border_linkpoint.scheduler_w_border_linkpoint_hover { background-color: black; }
.scheduler_w_border_event.scheduler_w_border_event_version .scheduler_w_border_event_inner { overflow:hidden; background-color: #cfdde8; background-image: linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent); background-size: 20px 20px; }
.scheduler_w_border_crosshair_vertical, .scheduler_w_border_crosshair_horizontal, .scheduler_w_border_crosshair_left, .scheduler_w_border_crosshair_top { background-color: gray; opacity: 0.2; }
.scheduler_w_border_link_dot { border-radius: 10px; background-color: red; }
.scheduler_w_border_task_milestone .scheduler_w_border_event_inner { position:absolute;top:16%;left:16%;right:16%;bottom:16%; background: #38761d; border: 0px none; transform: rotate(45deg); filter: none; }
.scheduler_w_border_event_left { white-space: nowrap; padding-top: 5px; color: #666; cursor: default; }
.scheduler_w_border_event_right { white-space: nowrap; padding-top: 5px; color: #666; cursor: default; }
.scheduler_w_border_selectionrectangle { background-color: #0000ff; border: 1px solid #000033; opacity: 0.4; }
.scheduler_w_border_link_shadow { border:1px solid black; }
.scheduler_w_border_link_shadow_circle { background-color:black; }
.scheduler_w_border_link_hover { box-shadow: 0px 0px 2px 2px rgba(255, 0, 0, 0.3); }
.scheduler_w_border_block { background-color: #808080; opacity: 0.5; }
.scheduler_w_border_main:focus { outline: none; }
.scheduler_w_border_cell_focus { outline: red 2px solid; z-index: 100; opacity: 0.5; }
.scheduler_w_border_shadow_invalid .scheduler_w_border_shadow_inner { outline: red 2px solid; z-index: 100; opacity: 0.5; }
.scheduler_w_border_sorticon { opacity: 0.2;background-position: center center; background-repeat: no-repeat; cursor: pointer; }
.scheduler_w_border_sorticon.scheduler_w_border_sorticon_asc { background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlnb24gcG9pbnRzPSI1IDEuNSwgMTAgMTAsIDAgMTAiLz48L3N2Zz4='); }
.scheduler_w_border_sorticon.scheduler_w_border_sorticon_desc { background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBvbHlnb24gcG9pbnRzPSI1IDguNSwgMCAwLCAxMCAwIi8+PC9zdmc+'); }
.scheduler_w_border_sorticon.scheduler_w_border_sorticon_active { opacity: 1; }
.scheduler_w_border_loading { background-color: orange; color: white; padding: 2px; }
