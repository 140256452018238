
@mixin DisplayFlex {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin TextOverflow {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin LayoutWrapper {
	float: left;
	width: 100%;
	height: 100%;
}

@mixin keyframe ($animation_name) {
	@-webkit-keyframes #{$animation_name} {
		@content;
	}
	
	@-moz-keyframes #{$animation_name} {
		@content;
	}
	
	@-o-keyframes #{$animation_name} {
		@content;
	}
	
	@keyframes #{$animation_name} {
		@content;
	}
}

@mixin animation ($delay, $duration, $animation, $direction: normal, $fillmode: forwards, $iteration: initial) {
	-webkit-animation-delay: $delay;
	-webkit-animation-duration: $duration;
	-webkit-animation-name: $animation;
	-webkit-animation-fill-mode: $fillmode;
	-webkit-animation-direction: $direction;
	-webkit-animation-iteration-count: $iteration;
	
	-moz-animation-delay: $delay;
	-moz-animation-duration: $duration;
	-moz-animation-name: $animation;
	-moz-animation-fill-mode: $fillmode;
	-moz-animation-direction: $direction;
	-moz-animation-iteration-count: $iteration;
	
	animation-delay: $delay;
	animation-duration: $duration;
	animation-name: $animation;
	animation-fill-mode: $fillmode;
	animation-direction: $direction;
	animation-iteration-count: $iteration;
}

@mixin br($br) {
	-webkit-border-radius: $br;
	-moz-border-radius: $br;
	border-radius: $br;
}

@mixin box-shadow($shadow, $second-shadow: null) {
	-webkit-box-shadow: $shadow, $second-shadow;
	-moz-box-shadow: $shadow, $second-shadow;
	box-shadow: $shadow, $second-shadow;
}

@mixin transition($t) {
	-webkit-transition: $t;
	-moz-transition: $t;
	-ms-transition: $t;
	-o-transition: $t;
	transition: $t;
}

@mixin transform($t) {
	-webkit-transform: $t;
	-moz-transform: $t;
	-ms-transform: $t;
	-o-transform: $t;
	transform: $t;
}

@mixin transform-origin($to) {
	-webkit-transform-origin: $to;
	-moz-transform-origin: $to;
	-ms-transform-origin: $to;
	-o-transform-origin: $to;
	transform-origin: $to;
}

@mixin pseudo-border {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	pointer-events: none;
}

@mixin absolute-center {
	position: absolute;
	left: 50%;
	top: 50%;
	@include transform(translate(-50%, -50%));
}

@mixin clearfix {
	&:before, &:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@function toRem($number) {
	@return ($number / $base-font-size) + rem;
}

.scroll-bar-style {
	&::-webkit-scrollbar {
		width: 2px;
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 4px 0 0 #191f26;
	}
	&::-webkit-scrollbar-thumb {
		-webkit-box-shadow: inset 4px 0 0 #ffffff;
	}
}

.pointer-events-none {
	pointer-events: none;
	> * {
		pointer-events: all;
	}
}