/* 
DayPilot Navigator Theme 
https://themes.daypilot.org/navigator/theme/kyy7ml
Theme Designer Version: 2020.06.01.40518
*/
.less_ugly_main { border-left: 1px solid #e8eaed;border-right: 1px solid #e8eaed;border-bottom: 1px solid #e8eaed; font-family: rubik; font-size: 12; }
.less_ugly_main *, .less_ugly_main *:before, .less_ugly_main *:after { box-sizing: border-box; }
.less_ugly_line { border-bottom: 1px solid #e8eaed; }
/* month header */ 
.less_ugly_title, .less_ugly_titleleft, .less_ugly_titleright { 
	border-top: 1px solid #e8eaed;
	color: #2f323c;
	background: #e8eaed;
}
.less_ugly_title { text-align: center; }
.less_ugly_titleleft, .less_ugly_titleright { text-align: center; }
/* day headers */
.less_ugly_dayheader { 
	color: #2f323c;
	background: #ffffff;
	padding: 0px;
	text-align: center;
}
/* day cells */
.less_ugly_cell { 
	color: #2f323c;
	background: #ffffff;
	text-align: center;
}
.less_ugly_cell_text {
	padding: 0px;
}
.less_ugly_weeknumber { 
	color: #2f323c;
	background: #ffffff;
	text-align: center;
	padding: 0px;
}
.less_ugly_weekend { 
	color: #7a8086;
	background: #f0f0f0;
	text-align: center;
	padding: 0px;
}
.less_ugly_dayother { color: gray; }
.less_ugly_todaybox { border: 1px solid red; }
.less_ugly_busy { font-weight: bold; }
.less_ugly_select .less_ugly_cell_box { background-color: #00ccf0; opacity: 0.5; }
