$width: 5rem;
$color: #4fc8ed;
.positioning {
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.loading-sticks {
  height: $width;
  width: $width;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .loading {
    flex: 0 0 20%;
    height: 30%;
    animation: pulse 1s ease infinite;
    background-color: $color;
    animation-delay: 0;
    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes pulse {
  0% {
    height: 30%;
  }
  50% {
    height: 100%;
  }
  100% {
    height: 30%;
  }
}

.loader-spinner {
  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &:before {
      display: block;
      width: 15%;
      height: 15%;
      margin: 0 auto;
      content: "";
      border-radius: 100%;
      background-color: $color;
      transform: translate(-50%, -50%);
      animation: spinner 1.2s infinite ease-in-out both;
    }
    &:nth-child(2) {
      transform: rotate(30deg);
      &:before {
        animation-delay: -1.1s;
      }
    }
    &:nth-child(3) {
      transform: rotate(60deg);
      &:before {
        animation-delay: -1s;
      }
    }
    &:nth-child(4) {
      transform: rotate(90deg);
      &:before {
        animation-delay: -0.9s;
      }
    }
    &:nth-child(5) {
      transform: rotate(120deg);
      &:before {
        animation-delay: -0.8s;
      }
    }
    &:nth-child(6) {
      transform: rotate(150deg);
      &:before {
        animation-delay: -0.7s;
      }
    }
    &:nth-child(7) {
      transform: rotate(180deg);
      &:before {
        animation-delay: -0.6s;
      }
    }
    &:nth-child(8) {
      transform: rotate(210deg);
      &:before {
        animation-delay: -0.5s;
      }
    }
    &:nth-child(9) {
      transform: rotate(240deg);
      &:before {
        animation-delay: -0.4s;
      }
    }
    &:nth-child(10) {
      transform: rotate(270deg);
      &:before {
        animation-delay: -0.3s;
      }
    }
    &:nth-child(11) {
      transform: rotate(300deg);
      &:before {
        animation-delay: -0.2s;
      }
    }
    &:nth-child(12) {
      transform: rotate(330deg);
      &:before {
        animation-delay: -0.1s;
      }
    }
  }
}

@keyframes spinner {
  0% {
    transform: scale(0, 0);
  }
  10% {
    transform: scale(0, 0);
  }
  50% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(0, 0);
  }
}
