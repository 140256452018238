// dark: '#2F323C',
// main: '#8F9197',

// Box shadows
@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;       
          box-shadow: $shadow;
}

.mentions {
  margin-top: 5px;
  textarea {
    padding: 5px 10px;
    color: #2f323c;
    border-radius: 4px;
    border-color: #D9DADC;
    line-height: 200%;
  }

  
  textarea:read-only {
    border: 1px solid transparent;
    outline-color: transparent;
  }
  

  textarea::placeholder {
    color: #D9DADC;
  }
}

.mentions__readonly {
  textarea {
    padding: 0;
    border: 0;
  }
  .mentions__highlighter {
    padding: 0;
  }

  .mentions__control {
    min-height: 0px;
  }
}

.mentions__control {
  min-height: 60px;
}
.mentions__input {
  line-height: 200%;
}

.mentions__highlighter {
  line-height: 200%;
  padding: 5px 10px;
}

.mentions__suggestions {
  z-index: 2 !important;
}

.mentions__mention {
  position: relative;
  z-index: 1;

  pointer-events: none;
  cursor: pointer;
  padding: 0 1px;
  margin: 0 0 0 -3px;
  // color: white;
  color: #2f323c;
  background: #e8eaed;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  border-radius: 25px;
}


.mentions__suggestions__list {
  border-radius: 4px;
  background-color: white;
  min-width: 200px;
  // box-shadow: 1px 1px #666;
  @include box-shadow(
    0px 2px 1px -1px rgba(0,0,0,0.2),
    0px 1px 1px 0px rgba(0,0,0,0.14),
    0px 1px 3px 0px rgba(0,0,0,0.12)
    ) ;

}

.mentions__suggestions__item {
  padding: 16px 32px;
}

.mentions__suggestions__item--focused {
  background-color: #E8EAED;
}

