@import '../../../assets/scss/variables';
.status-list {
  li {
    padding: 0 !important;

    > div {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
  }

  .item-list-selected {
    background-color:$color-secondary-dark;
  }

  .status-label {
    width: 100%;
    height: 100%;
    padding-block: 6px;
  }
}

.status-picker {
  button {
    text-transform: uppercase;
  }

  width: 280px;
  max-height: 509px;
  position: relative;

  .status-picker-list-container {
    width: 280px;
    max-height: 437px;
    position: relative;
    overflow-y: auto;
  }

  .status-picker-footer {
    width: 280px;
    height: 72px;
    display: flex;
    padding: 1px

  }

  .status-picker-list {

    display: flex;
    align-items: center;
    align-content: center;
    margin: 4px;
  }


  .status-picker-list-editor {
    display: flex;
    align-items: center;
    align-content: center;
    margin: 0 4px;

    input {
      height: inherit;
      padding: 4.5px 14px;
      font-size: 0.875rem;
    }
  }

  .color-button {
    cursor: pointer;
    width: 12px;
    height: 32px;
    border-radius: 4px;

  }
  .delete-button{
    padding: 0;
    svg {
      fill: $color-secondary-middle !important;
    }
  }
}

.drag-handle {
  svg {
    fill: $color-secondary-drag-handle !important;
  }
}

.status-button-container {
  span.text {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  height: 80%;
  :hover {
    span.down-arrow{
      opacity: 1;
    }
    span.text {
      margin-right: 10px;
    }
  }
  span.down-arrow {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    align-content: center;
    opacity: 0;
    flex: 0;
    svg {
      fill: white;
    }
  }

  display: flex;
  flex: 1;
  width: 100%;

  .status-button {
    svg {
      &:hover {
        opacity: 1;
      }

      opacity: 1;
    }

    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;

    .status-label {
      width: 100%;
      height: 100%;
      padding-block: 6px;
    }
  }
}

