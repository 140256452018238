
.container-chip{
    width: 100%;
}
.first-chip{
    display: unset !important;
}

.visible-chip{
    position: relative;
    display: inline-block;
}
.hidden-chip{
    position: absolute;
    visibility: hidden;
}
